/* custom css  */
input.mm-disabled,
select.mm-disabled {
  background-color: transparent;
  border: 0;
  padding: 10px 0;
}

input.mm-disabled.profile,
select.mm-disabled.profile {
  /* padding: 0;
  margin: 0; */
  /* max-width: 300px; */
}
.dashboard-nav.active {
  font-weight: 700 !important;
}
